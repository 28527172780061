<template lang="">
    <MoviePage/>
</template>
<script>
import MoviePage from '@/components/MoviePage.vue'
export default {
    name: 'MovieView',
    components: {
        MoviePage
    }
}
</script>
<style lang="">
    
</style>